@import url('https://rsms.me/inter/inter.css');
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  user-select: none;
}

.video-not-ready {
  /* display: none; */
}

.video-ready {
  display: block;
}
