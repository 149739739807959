.container {
  min-width: inherit;
  max-width: inherit;
  display: grid;
  grid-template-columns: 1fr;
  background-color: rgba(0, 0, 0, 0.034);
  border-radius: 6px;
  margin-bottom: 0.4rem;
  margin: 0 auto;
  margin-bottom: 0.8rem;
  transition: all 0.3s ease;
}

.container:hover {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.container .imgContainer {
  padding: 0.3rem;
  margin: 0;
  width: 98%;
  height: 200px;
  margin: auto;
}

.container .imgContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.container .ogText {
  width: 80%;
  padding: 0.5rem;
  margin: 0;
}

.container .ogText .title {
  font-weight: bold;
  font-size: 13px;
  padding: 0;
  margin: 0;
}

.container .ogText .desc {
  font-weight: normal;
  font-size: 13px;
  padding: 0;
  margin: 0;
}

.container .ogText .url {
  font-weight: normal;
  font-size: 13px;
  padding: 0;
  margin: 0 0 12px 0;
  color: gray;
}
