.container {
  display: flex;
  gap: 0.8rem;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.container .callIcon {
  width: 30px;
  height: 30px;
}

/* incoming callicon should have bg */
.container .incoming {
  background: #f4f9ff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  padding: 10px;
}

.container p {
  padding: 0;
  margin: 0;
}

.container .content {
  display: flex;
  padding-right: 1rem;
  justify-content: start;
  flex-direction: column;
}

.container .content .message {
  font-size: 14px;
  font-weight: bold;
}

.container .content .duration {
  font-size: 12px;
  color: gray;
  margin-left: 1px;
}
