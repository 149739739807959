.contactItemInfo {
  display: flex;
  align-items: center;
  gap: 1rem;

  .contactItemName {
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: #17191c;
  }

  .contactItemNumber {
    font-size: 13px;
    color: #757d8b;
    line-height: 16px;
  }

  .contactItemAvatar {
    width: 40px;
    height: 40px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.isWaafiNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.chatButton {
  background-color: transparent;
  border: transparent;
  cursor: pointer;
  color: #000;
  font-size: 14px;
  margin-top: 1rem;
  border: 1px solid #00d15b;
  padding: 0.4rem 2rem;
  border-radius: 2rem;
  transition: all 0.3s;
}

.chatButton:hover {
  background-color: #00d15b;
  color: #fff;
}
